import Vue from 'vue'
import App from './App.vue'
import { ToggleButton } from 'vue-js-toggle-button'

Vue.config.productionTip = false

Vue.component('ToggleButton', ToggleButton)
Vue.component('AudioRecorder', require('./components/record-recorder.vue').default);
Vue.component('UsbRecorder', require('./components/record-webusb.vue').default);

Vue.prototype.$eventBus = Vue.prototype.$eventBus || new Vue

new Vue({
  render: h => h(App),
}).$mount('#app')
