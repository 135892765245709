<template>
  <LineC
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
  import { Line as LineC } from 'vue-chartjs/legacy'
  import { PointElement, Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(PointElement, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale)

export default {
  name: 'LineChart',
  components: { LineC },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      chartData: {
          labels: [ '0.1', '0.2', '0.3', '0.4', '0.5', '0.6', '0.7', '0.8', '0.9', '1' ],
          datasets: [ { data: [10, 12, 44, 44, 40, 15, 12, 10, 10, 10],  tension: 0.4} ]
        },
      chartOptions: {
        responsive: true,
        animation: {/*easing: "easeInSine"*/ duration: '500'},
        scales: {
            y: {
              min: -1.2,
              max: 1.2
            }
        }
      }
    }
  }
}
</script>