<style lang="scss">
  @import '../scss/icons';
</style>

<template>
  <icon-button name="save" class="ar-icon ar-icon__xs ar-icon--no-border" @click.native="upload"/>
</template>

<script>
  import IconButton from './icon-button'

  export default {
    props: {
      record: { type: Object }
    },
    components: {
      IconButton
    },
    methods: {
    }
  }
</script>
