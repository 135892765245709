import Serial from './usb-serial'

export default class {
  constructor (options = {}) {
    this.beforeRecording = options.beforeRecording
    this.pauseRecording  = options.pauseRecording
    this.afterRecording  = options.afterRecording
    this.micFailed       = options.micFailed
    this.format          = options.format
    this.updateAudioDevices  = options.updateAudioDevices

    this.encoderOptions = {
      bitRate    : options.bitRate,
      sampleRate : options.sampleRate
    }

    this.audioDevices = []

    this.bufferSize = 4096
    this.records    = []
    this.sampleRate = this.encoderOptions.sampleRate

    this.isPause     = false
    this.isRecording = false

    this.duration = 0
    this.startTime = 0
    this.frames = 0
    this.bytesPerFrame = 0;

    this.wavSamples = []

    this._duration = 0

    this.serial = new Serial()

  }

  connect() {
    this.serial.select(this.capture.bind(this))
  }

  start () {
    let string = "start";
    this.serial.send(string)

    this.startTime = new Date().getTime();

    this.isRecording = true;
  }

  stop () {
    let string = "stop";
    this.serial.send(string)

    this.isRecording = false;
  }

  pause () {
    this.stream.getTracks().forEach((track) => track.stop())
    this.input.disconnect()
    this.processor.disconnect()

    this._duration = this.duration
    this.isPause = true

    this.pauseRecording && this.pauseRecording('pause recording')
  }

  recordList () {
    return this.records
  }

  lastRecord () {
    return this.records.slice(-1).pop()
  }

  capture (data) {
    //this.wavSamples.push(new Float32Array(data))
/*
    if (this._isX4()) {
      //add X4Reciever parsing here
    } else {
      //Other formates like X2?
      console.log('unsupported format')
    }
*/
    //this.duration = parseFloat(this._duration) + parseFloat(this.context.currentTime.toFixed(2))

    this.bytesPerFrame = data.byteLength
    this.duration = (new Date().getTime()) - this.startTime
    this.frames += data.byteLength


    /* Test data */
    for (let i=0; i < data.byteLength; i++) {
      if(data.getInt8(i) != i)
      {
        console.log("capture unexpected data", data.getInt8(i), i);
      }
    }
  }

/*
  _isX4 () {
    return this.format.toLowerCase() === 'x4'
  }
*/
}
