<style lang="scss">
  .wt {
    width: 500px;
    font-family: 'Roboto', sans-serif;
    border-radius: 16px;
    background-color: #FAFAFA;
    box-shadow: 0 4px 18px 0 rgba(0,0,0,0.17);
    position: left;
    box-sizing: content-box;

    &-content {
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-records {
      height: 138px;
      padding-top: 1px;
      overflow-y: auto;
      margin-top: 20px;
      margin-bottom: 20px;

      &__record {
        width: 320px;
        height: 45px;
        padding: 0 10px;
        margin: 0 auto;
        line-height: 45px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #E8E8E8;
        position: relative;

        &--selected {
          border: 1px solid #E8E8E8;
          border-radius: 24px;
          background-color: #FFFFFF;
          margin-top: -1px;
          padding: 0 34px;
        }
      }
    }

    &-recorder {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__button {
        background-color: #FFFFFF;
        color: #676767;
        border-radius: 4px;
        border: 2px solid #55AEDB;
        padding: 10px 30px;
        font-size:22px;
        margin-top: 20px;
        margin-bottom: 16px;
      }

      &__button:hover {
        color: #2c2c2c;
      }

      &__rec {
        position: absolute;
        top: 7px;
        right: -65px;
      }

      &__stop {
        position: absolute;
        top: 15px;
        right: -115px;
      }

      &__usb-select {
        color: #AEAEAE;
        font-size: 12px;
        margin-top: 12px;
      }

      &__duration {
        color: #AEAEAE;
        font-size: 20px;
        font-weight: 300;
        margin-top: 10px;
        margin-bottom: 16px;
        margin-right: 16px;
        margin-left: 90px;
      }

      &__measured-bw {
        color: #AEAEAE;
        font-size: 12px;
      }

      &__chart {
        margin-left: 30px;
        margin-right: 30px;
      }
    }

    &__text {
      color: rgba(84,84,84,0.5);
      font-size: 16px;
    }

    &__rm {
      cursor: pointer;
      position: absolute;
      width: 6px;
      height: 6px;
      padding: 6px;
      line-height: 6px;
      margin: auto;
      left: 10px;
      bottom: 0;
      top: 0;
      color: rgb(244, 120, 90);
    }

    &__downloader {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 85px;
    }
  }

  @import '../scss/icons';
</style>

<template>
  <div class="wt">
    <div class="wt-content">
      <div class="wt-recorder">
        <button class="ar-icon ar-icon__button" @click="connectWebUsb">Connect</button>
        <icon-button
          class="ar-icon ar-icon__lg wt-recorder__rec"
          :name="iconButtonType"
          :class="{
            'ar-icon--rec': isRecording,
            'ar-icon--pulse': isRecording
          }"
          @click.native="toggleRecorder"/>
        <icon-button
          class="ar-icon ar-icon__sm wt-recorder__stop"
          name="stop"
          @click.native="stopRecorder"/>
      </div>

      <span>
        <span class="wt-recorder__duration">{{recordedTime}}</span>
        <span class="wt-recorder__measured-bw">Measured BW: {{measuredBandWidth}} Bytes/s</span>
      </span>

      <div class="wt-recorder__chart">
        <LiveChart ref="lineRef" :chartData="samples"/>
      </div>
      <div class="ar-records">
        <div
          class="ar-records__record"
          :class="{'ar-records__record--selected': record.id === selected.id}"
          :key="record.id"
          v-for="(record, idx) in recordList"
          @click="choiceRecord(record)">
            <div
              class="ar__rm"
              v-if="record.id === selected.id"
              @click="removeRecord(idx)">&times;</div>
            <div class="ar__text">Record {{idx + 1}}</div>
            <div class="ar__text">{{record.duration}}</div>

            <downloader
              v-if="record.id === selected.id && showDownloadButton"
              class="ar__downloader"
              :record="record"
              :filename="filename"/>
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
/* eslint-disable */
  import Downloader  from './record-downloader'
  import IconButton  from './icon-button'
  import WebUsbRecorder    from '@/lib/webusb-recorder'
  import Uploader    from './record-uploader'
  import UploaderPropsMixin from '@/mixins/uploader-props'
  import { convertTimeMMSS }  from '@/lib/utils'
  import LiveChart from './live-chart'
  

  export default {
    mixins: [UploaderPropsMixin],
    props: {
      time     : { type: Number },

      bitRate    : { type: Number, default: 128   },

      showDownloadButton : { type: Boolean, default: true },
      showUploadButton   : { type: Boolean, default: true },
  
      failedUpload     : { type: Function },
      beforeUpload     : { type: Function },
      successfulUpload : { type: Function },
      selectRecord     : { type: Function },

    },
    data () {
      return {
        isUploading   : false,
        recorder      : this._initRecorder(),
        recordList    : [],
        selected      : {},
        selectedDevice : {},
        uploadStatus  : null,
        sampleData: {
          labels: [ '0.1', '0.2', '0.3', '0.4', '0.5', '0.6', '0.7', '0.8', '0.9', '1' ],
          datasets: [ { data: [10, 12, 44, 44, 40, 15, 12, 10, 10, 10],  tension: 0.4} ]
        },
      }
    },
    components: {
      Downloader,
      IconButton,
      Uploader,
      LiveChart
    },
    mounted () {
      this.$eventBus.$on('start-upload', () => {
        this.isUploading = true
        this.beforeUpload && this.beforeUpload('before upload')
      })

      this.$eventBus.$on('end-upload', (msg) => {
        this.isUploading = false

        if (msg.status === 'success') {
          this.successfulUpload && this.successfulUpload(msg.response)
        } else {
          this.failedUpload && this.failedUpload(msg.response)
        }
      })

      //console.log('refs' + this.$refs.line)
      //setInterval(this.updateData().bind(this), 1000)

      setInterval(()=> {
            this.updateData()
      }, 200);
    },
    beforeDestroy () {
      this.stopRecorder()
    },
    methods: {
      toggleRecorder () {
        if (!this.isRecording || (this.isRecording && this.isPause)) {
          this.recorder.start()
        } else {
          this.recorder.pause()
        }
      },
      stopRecorder () {
        if (!this.isRecording) {
          return
        }

        this.recorder.stop()
        this.recordList = this.recorder.recordList()
      },
      removeRecord (idx) {
        this.recordList.splice(idx, 1)
        this.$set(this.selected, 'url', null)
        this.$eventBus.$emit('remove-record')
      },
      choiceRecord (record) {
        if (this.selected === record) {
          return
        }
        this.selected = record
        this.selectRecord && this.selectRecord(record)
      },
      _initRecorder () {
        return new WebUsbRecorder()
      },
      connectWebUsb () {
        console.log('connectWebUsb')
        this.recorder.connect()
      },
      updateData () {

      if(this.recorder.isRecording)
      {
        //console.log(this.$refs.lineRef)
        this.$refs.lineRef.chartData.datasets[0].data.shift()
        this.$refs.lineRef.chartData.datasets[0].data.push(Math.sin((this.recorder.duration)))

      }
/*
        if(this.recorder.isRecording)
        {
          this.sampleData.datasets[0].data.push(this.recorder.duration/1000)
          this.sampleData.datasets[0].data.shift();
          this.$refs.lineRef.updateChart()
        }
*/
      }
    },
    computed: {
      iconButtonType () {
        return this.isRecording && this.isPause ? 'record' : this.isRecording ? 'pause' : 'record'
      },
      isPause () {
        return this.recorder.isPause
      },
      isRecording () {
        return this.recorder.isRecording
      },
      recordedTime () {
        if (this.time && this.recorder.duration >= this.time * 60) {
          this.stopRecorder()
        }

        return convertTimeMMSS(this.recorder.duration/1000)
      },
      recordedFrames () {
        return this.recorder.frames
      },
      measuredBandWidth () {
        if(this.recorder.duration > 0) {
          //console.log("frames: ", this.recorder.frames)
          //console.log("bytesPerFrame: ", this.recorder.bytesPerFrame)
          //console.log("duration: ", this.recorder.duration)
          this.lastmeasuredBandWidth = (this.recorder.frames / (this.recorder.duration / 1000)) * this.recorder.bytesPerFrame
        }

        return this.lastmeasuredBandWidth       
      },
      bytesPerFrame()
      {
        return parseFloat(this.recorder.bytesPerFrame)
      },
      samples()
      {
        return this.sampleData
      }
    }
  }
</script>

