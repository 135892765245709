class Port {
  constructor (device) {
    this.device_ = device;
  }

  connect() {
    let readLoop = () => {
      //console.log("readLoop");
      const {
        endpointNumber
      } = this.device_.configuration.interfaces[0].alternate.endpoints[0]
      //console.log("readLoop: transferIn");
      this.device_.transferIn(endpointNumber, 64).then(result => {
        if( result.data.byteLength > 0)
        {
          //console.log("readLoop: result", result);
          this.onReceive(result.data);
        }
        readLoop();
      }, error => {
        console.log("readLoop: error", error);
        this.onReceiveError(error);
      });
    };

    return this.device_.open()
        .then(() => {
          if (this.device_.configuration === null) {
            return this.device_.selectConfiguration(1);
          }
        })
        .then(() => this.device_.claimInterface(0))
        .then(() => {
          readLoop();
        });
  }

  disconnect() {
    return this.device_.close();
  }

  send(data) {
    const {
      endpointNumber
    } = this.device_.configuration.interfaces[0].alternate.endpoints[1]
    return this.device_.transferOut(endpointNumber, data);
  }
}

export default class {
  constructor () {
    this.port = {}
  }
  
  getPorts() {
    return navigator.usb.getDevices().then(devices => {
      return devices.map(device => new this.Port(device));
    });
  }

  requestPort() {
    const filters = [
      { 'vendorId': 0x2fe3, 'productId': 0x0100 },
      { 'vendorId': 0x2fe3, 'productId': 0x00a },
      { 'vendorId': 0x8086, 'productId': 0xF8A1 },
    ];
    return navigator.usb.requestDevice({ 'filters': filters }).then(
      device => new Port(device)
    );
  }

  connect(capture_callback) { 
    console.log("connect");
    this.port.connect().then(() => {
      //console.log("Regestering onReceive");  
      this.port.onReceive = data => {
        capture_callback(data);
        //let textDecoder = new TextDecoder();
        //console.log("Received:", textDecoder.decode(data));
      }
      this.port.onReceiveError = error => {
        console.error(error);
        this.port.disconnect();
      };
    });

    return 0;
  }

  send(string) {
    console.log("sending to serial:" + string.length);
    if (string.length === 0)
      return;
    console.log("sending to serial: [" + string +"]\n");

    let view = new TextEncoder('utf-8').encode(string);
    console.log('view: ' + view);
    if (this.port) {
      this.port.send(view);
    }
  }

  select(capture_callback) {
    this.requestPort().then(selectedPort => {
      this.port = selectedPort;
      this.style = "visibility: hidden";
      this.connect(capture_callback)
    })
  }
}
