<style lang="scss">
  .select_button {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    padding: 20px;
  }
</style>

<template>
  <div class="row">
    <div class="select_button">
        WebUsb  <toggle-button :value="false"
                                color="{checked: '#82C7EB', unchecked: '#82C7EB', disabled: '#82C7EB'}"
                                v-model="selectUsbAudioRecorder"/>  USB Audio
    </div>

    <audio-recorder v-if="selectUsbAudioRecorder"
      upload-url="some url"
      filename="x4-recording"
      format="x4"
      :attempts="3"
      :time="2"
      :headers="headers"
      :before-recording="callback"
      :pause-recording="callback"
      :after-recording="callback"
      :select-record="callback"
      :before-upload="callback"
      :successful-upload="callback"
      :failed-upload="callback"
      :bit-rate="192"/>
    <usb-recorder v-if="!selectUsbAudioRecorder"/>
  </div>
</template>

<script>
  export default {
    name: 'app',
    data () {
      return {
        mp3: '/demo/example.mp3',
        showRecorder: true,
        selectUsbAudioRecorder: false,
        headers: {
          'X-Custom-Header': 'some data'
        }
      }
    },
    methods: {
      callback (msg) {
        console.debug('Event: ', msg)
      },
    }
  }
</script>
