import X4Reciever from './x4-reciever'
import { convertTimeMMSS } from './utils'

export default class {
  constructor (options = {}) {
    this.beforeRecording = options.beforeRecording
    this.pauseRecording  = options.pauseRecording
    this.afterRecording  = options.afterRecording
    this.micFailed       = options.micFailed
    this.format          = options.format
    this.updateAudioDevices  = options.updateAudioDevices

    this.encoderOptions = {
      bitRate    : options.bitRate,
      sampleRate : options.sampleRate
    }

    this.audioDevices = []

    this.bufferSize = 4096
    this.records    = []
    this.sampleRate = this.encoderOptions.sampleRate

    this.isPause     = false
    this.isRecording = false

    this.duration = 0
    this.volume   = 0
    this.frames = 0
    this.framesPerEvent = 0;

    this.wavSamples = []

    this._duration = 0
  }

  audioDevicesList () {
    const constraints = {
      video: false,
      audio: {
        channelCount: 1,
        echoCancellation: false,
      }
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(() => {
        navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          this.updateAudioDevices && this.updateAudioDevices(devices)
        })
    })
  }

  start (selectedDevice, sampleRate) {
    const constraints = {
      video: false,
      audio: {
        channelCount: 1,
        echoCancellation: false,
        deviceId: {'exact': selectedDevice.deviceId},
      }
    }

    this.beforeRecording && this.beforeRecording('start recording')

    this.sampleRate = sampleRate
    navigator.mediaDevices
             .getUserMedia(constraints)
             .then(this._micCaptured.bind(this))
             .catch(this._micError.bind(this))          

    this.isPause     = false
    this.isRecording = true
  }

  stop () {
    this.stream.getTracks().forEach((track) => track.stop())
    this.input.disconnect()
    this.processor.disconnect()
    this.context.close()

    let record = null
        
    let x4Reciever = new X4Reciever({
      bufferSize : this.bufferSize,
      sampleRate : this.encoderOptions.sampleRate,
      samples    : this.wavSamples
    })
    record = x4Reciever.finish()
    this.wavSamples = []

    record.duration = convertTimeMMSS(this.duration)
    this.records.push(record)

    this._duration = 0
    this.duration  = 0

    this.isPause     = false
    this.isRecording = false

    this.afterRecording && this.afterRecording(record)
  }

  pause () {
    this.stream.getTracks().forEach((track) => track.stop())
    this.input.disconnect()
    this.processor.disconnect()

    this._duration = this.duration
    this.isPause = true

    this.pauseRecording && this.pauseRecording('pause recording')
  }

  recordList () {
    return this.records
  }

  lastRecord () {
    return this.records.slice(-1).pop()
  }

  _micCaptured (stream) {

    this.context    = new(window.AudioContext || window.webkitAudioContext)({sampleRate: this.sampleRate})
    this.duration   = this._duration
    this.input      = this.context.createMediaStreamSource(stream)
    this.processor  = this.context.createScriptProcessor(this.bufferSize, 1, 1)
    this.stream     = stream
/*
    const constraints = {
      audio: {
        sampleRate: {exact: this.sampleRate}
      }
    }

    this.stream.getAudioTracks()[0].applyConstraints (constraints)
    .then (()=> {

      const mediaRecorder = new MediaRecorder(stream)
      console.log('set sampleRate: ' + this.sampleRate)
    } )
    .catch(console.error)
*/
    console.log('using: ' + this.stream.getAudioTracks()[0].label)
    console.log('capabilities: ' + JSON.stringify(this.stream.getAudioTracks()[0].getCapabilities()));
    console.log('settings: ' + JSON.stringify(this.stream.getAudioTracks()[0].getSettings()));
  
    console.log('this.context.sampleRate: ' + this.context.sampleRate)

    this.processor.onaudioprocess = (ev) => {
      const sample = ev.inputBuffer.getChannelData(0)
      let sum = 0.0

      this.wavSamples.push(new Float32Array(sample))

      if (this._isX4()) {
        /* add X4Reciever parsing here */
      } else {
        /* Other formates like X2? */
        console.log('unsupported format')
      }

      for (let i = 0; i < sample.length; ++i) {
        sum += sample[i] * sample[i]
      }

      this.duration = parseFloat(this._duration) + parseFloat(this.context.currentTime.toFixed(2))
      this.volume = Math.sqrt(sum / sample.length).toFixed(2)

      this.frames += sample.length
      this.framesPerEvent = sample.length
    }

    this.input.connect(this.processor)
    this.processor.connect(this.context.destination)
  }

  _micError (error) {
    console.log('_micError: ' + error)
    this.micFailed && this.micFailed(error)
  }

  _isX4 () {
    return this.format.toLowerCase() === 'x4'
  }
}
